import { useState } from "react";
import "./SudokuGame.css";
import {
  Modal,
  ModalOverlay,
  Select,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  ModalFooter,
  Button,
  Box,
  Text
} from "@chakra-ui/react";

const generateInitialBoard = (difficulty) => {
  const board = Array.from({ length: 9 }, () => Array(9).fill(0));

  // Fill the diagonal of 3x3 matrices
  for (let i = 0; i < 9; i += 3) {
    fillDiagonal(board, i, i);
  }

  // Fill remaining blocks
  fillRemaining(board, 0, 3);

  // Remove cells based on difficulty
  let cellsToRemove;
  switch (difficulty) {
    case "easy":
      cellsToRemove = 40; // Easy level has more clues (40 cells are empty)
      break;
    case "medium":
      cellsToRemove = 50; // Medium level has fewer clues (50 cells are empty)
      break;
    case "hard":
      cellsToRemove = 60; // Hard level has even fewer clues (60 cells are empty)
      break;
    case "ultimate":
      cellsToRemove = 68; // Ultimate level has the fewest clues (64 cells are empty)
      break;
    default:
      cellsToRemove = 50; // Default is medium difficulty
  }

  removeCells(board, cellsToRemove);

  return board;
};

const fillDiagonal = (board, row, col) => {
  let num;
  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < 3; j++) {
      do {
        num = Math.floor(Math.random() * 9) + 1;
      } while (!isSafe(board, row, col, num));
      board[row + i][col + j] = num;
    }
  }
};

const fillRemaining = (board, i, j) => {
  if (j >= 9 && i < 8) {
    i = i + 1;
    j = 0;
  }
  if (i >= 9 && j >= 9) {
    return true;
  }

  if (i < 3) {
    if (j < 3) {
      j = 3;
    }
  } else if (i < 6) {
    if (j === Math.floor(i / 3) * 3) {
      j = j + 3;
    }
  } else {
    if (j === 6) {
      i = i + 1;
      j = 0;
      if (i >= 9) {
        return true;
      }
    }
  }

  for (let num = 1; num <= 9; num++) {
    if (isSafe(board, i, j, num)) {
      board[i][j] = num;
      if (fillRemaining(board, i, j + 1)) {
        return true;
      }
      board[i][j] = 0;
    }
  }
  return false;
};

const removeCells = (board, count) => {
  while (count > 0) {
    let row = Math.floor(Math.random() * 9);
    let col = Math.floor(Math.random() * 9);
    if (board[row][col] !== 0) {
      board[row][col] = 0;
      count--;
    }
  }
};

// Sudoku Solver Logic
const isSafe = (board, row, col, num) => {
  // Row and Column Check
  for (let x = 0; x < 9; x++) {
    if (board[row][x] === num || board[x][col] === num) {
      return false;
    }
  }

  // Subgrid Check
  const startRow = row - (row % 3);
  const startCol = col - (col % 3);
  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < 3; j++) {
      if (board[i + startRow][j + startCol] === num) {
        return false;
      }
    }
  }
  return true;
};

const solveSudoku = (board) => {
  let emptySpot = findEmptySpot(board);
  let row = emptySpot[0];
  let col = emptySpot[1];

  // No empty spot left, puzzle solved
  if (row === -1) {
    return true;
  }

  for (let num = 1; num <= 9; num++) {
    if (isSafe(board, row, col, num)) {
      board[row][col] = num;
      if (solveSudoku(board)) {
        return true;
      }
      board[row][col] = 0; // Reset on backtrack
    }
  }
  return false;
};

const findEmptySpot = (board) => {
  for (let i = 0; i < 9; i++) {
    for (let j = 0; j < 9; j++) {
      if (board[i][j] === 0) {
        return [i, j];
      }
    }
  }
  return [-1, -1]; // Indicates puzzle is solved or no empty spot
};

// Sudoku Board Component
const SudokuBoard = ({ board, onChange }) => {
  return (
    <div className="board">
      {board.map((row, rowIndex) => (
        <div key={rowIndex} className="row">
          {row.map((value, colIndex) => (
            <input
              key={colIndex}
              type="number"
              min={1}
              max={9}
              className="cell"
              value={value === 0 ? "" : value}
              onChange={(e) => onChange(rowIndex, colIndex, e.target.value)}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

// Main Sudoku Game Component
const SudokuGame = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [board, setBoard] = useState(generateInitialBoard("easy"));
  const [difficulty, setDifficulty] = useState("easy");

  const handleToggle = () => (isOpen ? onClose() : onOpen());

  const handleDifficultyChange = (event) => {
    setDifficulty(event.target.value);
  };

  const handleGenerateBoard = () => {
    const newBoard = generateInitialBoard(difficulty);
    setBoard(newBoard);
  };

  const handleCellChange = (row, col, value) => {
    const newBoard = [...board];
    const num = parseInt(value);

    // Check if the entered number is between 1 and 9
    if (value && (num < 1 || num > 9)) {
      // alert('Please enter a number between 1 and 9.');
      return;
    }

    // Check if the number can be placed in the current cell
    if (value && !isSafe(newBoard, row, col, num)) {
      // alert('This number cannot be placed here.');
      return;
    }

    newBoard[row][col] = num || 0;
    setBoard(newBoard);

    const hasEmpty = newBoard.some((item) => item.some((a) => a === 0));

    if (!hasEmpty) {
      handleToggle();
    }
  };

  const getModal = () => {
    return (
      <>
        <Modal size="xs" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Juhuuuu</ModalHeader>
            <ModalBody>Megcsináltad! &#129395; Új játék?</ModalBody>
            <ModalFooter>
              <Button
                colorScheme="teal"
                mr={3}
                onClick={() => {
                  onClose();
                  handleGenerateBoard();
                }}
              >
                Új játék
              </Button>
              <Button
                colorScheme="teal"
                mr={3}
                onClick={() => {
                  onClose();
                }}
              >
                Bezár
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  const handleSolve = () => {
    const newBoard = [...board];
    if (solveSudoku(newBoard)) {
      setBoard(newBoard);
    } else {
      alert("No solution exists for the current board!");
    }
  };

  return (
    <Box w="340px">
      <Box pt={6} pb={4}>
        <Text as="b" color="teal" fontSize="xl">Sudoku</Text>
      </Box>
      <SudokuBoard board={board} onChange={handleCellChange} />
      {getModal()}
      <Select
        w="80%"
        pt="4px"
        size="sm"
        value={difficulty}
        onChange={handleDifficultyChange}
      >
        <option value="easy">Könnyű</option>
        <option value="medium">Közepes</option>
        <option value="hard">Nehéz</option>
        <option value="ultimate">Kihívás</option>
      </Select>
      <Button onClick={handleGenerateBoard} colorScheme="teal" size="sm">
        Új játék
      </Button>
      <Button onClick={handleSolve} colorScheme="teal" size="sm">
        Megoldás
      </Button>
    </Box>
  );
};

export default SudokuGame;
