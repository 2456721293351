import { extendTheme } from "@chakra-ui/react"


const breakpoints = {
  base: '0em',
    sm: '500px',
    md: '900px',
    lg: '1000px',
    xl: '80em',
    '2xl': '96em',
}

const theme = extendTheme({
  breakpoints,
  styles: {
    global: {
      body: {
        fontFamily:'"Montserrat", sans-serif',
        fontOpticalSizing: 'auto',
        fontWeight: '400',
        fontStyle: 'normal',
        bg: 'gray.50',
        color: 'black',
      },
    },

    colors: {
      portfolio: {
        textColor: "#000",
        color: "white"
      },
      penzcentrum: {
        textColor: "#ee544c",
        color: "#000"
      },
      origo: {
        textColor: "white",
        color: "#001492"
      },
      hvg: {
        textColor: "white",
        color: "#e25900"
      },
      telex: {
        textColor: "white",
        color: "#135"
      },
      index: {
        textColor: "#000",
        color: "white"
      },
      hwsw: {
        textColor: "white",
        color: "#333"
      },
      hir24: {
        textColor: "white",
        color: "#353540"
      },
      hir444: {
        textColor: "white",
        color: "#000"
      },
    },
  },
})

export default theme