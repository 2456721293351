import { format } from "date-fns";

export const getDate = (date) => {
  if(!date) return 
  const pubDate = new Date(date);
  return format(pubDate.toISOString().replace("T", " ").replace(/\.\d{3}Z$/, ""), "yyyy-MM-dd HH:mm")
}

export const getHVGImage = (image: string, author: string) => {
  return author?.includes("hvg")
  ? `https://img.hvg.hu/Img/da658e97-86c0-40f3-acd3-b0a850f32c30/${
   image.split("id=")[1]
    }.jpg`
  :image
}

export const checkEntriesData = (entries, newData) => {
  const results = entries.filter(({ title: oldTitle }) => !newData.some(({ title: newTitle }) => newTitle === oldTitle));
  return results
}

export const getTopicName = (topic: string) => {
  if(topic === '/belfold') {
    return "belföldi"
  } else if (topic === '/kulfold') {
    return "külföldi"
  }  else if (topic === '/gazdasag') {
    return 'gazdasági'
  }  else if (topic === '/tech') {
    return 'tech'
  }  else if (topic === '/bulvar') {
    return 'bulvár'
  }  else if (topic === '/sport') {
    return 'sport'
  }  else if (topic === '/gasztro') {
    return 'gasztronómiai'
  }  else if (topic === '/eletmod') {
    return 'életmód'
  }  else if (topic === '/utazas') {
    return 'utazási'
  }  else if (topic === '/otthon') {
    return 'otthon'
  }  else if (topic === '/business') {
    return 'üzleti'
  }  else if (topic === '/auto') {
    return 'autós'
  }  else if (topic === '/aktualis') {
    return 'aktuális'
  }  else if (topic === '/kultura') {
    return 'kultúrális'
  } else if (topic === '/olimpia') {
    return 'Olimpia'
  } 

}