import { FC } from "react";
import { Box, Button, Flex, Grid, GridItem, Skeleton, SkeletonText, Stack, Text } from "@chakra-ui/react";
import NewsTemplate from "../newsTemplate/NewsTemplate";
import NewsBox from "../newsBox/NewsBox";
import { getTopicName } from "../utils/utils";

export type NewsProps = {
  title: string;
  description: string;
  pubdate: string;
  author: string;
  link: string;
  image: string;
  type: string;
  id: string;
};

type Props = {
  topic?: string;
  portal?: string;
  entries?: NewsProps[];
  newsBox?: NewsProps[];
  hasMore?: boolean;
  page?: number;
  setPage?: (page: number) => void;
  isHasMoreLoading?: boolean;
  searchText?: string;
  isLoading?: boolean
};

const NewsPost: FC<Props> = ({
  entries,
  hasMore,
  page,
  setPage,
  isHasMoreLoading,
  searchText,
  topic,
  isLoading,
  newsBox
}) => {

  const dataLoading = () => {
    if (isLoading) {
      return (
        <Stack pl="36px" pr="36px" pt="16px">
          {[1, 2, 3].map((item) => {
            return (
              <Box boxShadow="lg" bg="white" height="189px" p="18px" key={item}>
                <Grid templateColumns="repeat(9, 1fr)" gap={6}>
                  <GridItem colSpan={2}>
                    <Skeleton height="100%" />
                  </GridItem>
                  <GridItem colStart={3} colEnd={9}>
                    <SkeletonText
                      mt="1"
                      noOfLines={6}
                      spacing="4"
                      skeletonHeight="2"
                    />
                  </GridItem>
                </Grid>
              </Box>
            );
          })}
        </Stack>
      );
    }
  };
  
  return (
    <>
      <Flex display={{ base: "none", sm: "block", md: "none", lg: "none" }}>
        <Box marginLeft={12}>
          <Text as="b" fontSize="xl" color="teal.400">
            Pörgesd át a 10 legfrissebb {getTopicName(topic)} hírt!
          </Text>
        </Box>
        <NewsBox entries={newsBox} />
      </Flex>
      <Box>
        {searchText ? null : <>{dataLoading()}</>}
        <NewsTemplate entries={entries} searchText={searchText} />
      </Box>
      {hasMore && (
        <Box p={8} display="flex" justifyContent="center">
          <Button
            colorScheme="teal"
            onClick={() => setPage(page + 1)}
            isLoading={isHasMoreLoading}
          >
            Tovább olvasom
          </Button>
        </Box>
      )}
    </>
  );
};

export default NewsPost;
