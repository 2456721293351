import styled from 'styled-components'; 

const mainDarkColor = "#234E52";
  
export const Button = styled.div` 
   position: fixed;  
   width: 100%; 
   left: 50%; 
   bottom: 40px; 
   height: 40px; 
   font-size: 3rem; 
   z-index: 1; 
   cursor: pointer; 
   overscroll-behavior: contain;
   color: ${mainDarkColor}; 
`