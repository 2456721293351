import { Box, Button, Flex, useDisclosure } from "@chakra-ui/react";
import Impressum from "./components/impressum/Impressum";
import ProtectDataModal from "./components/protectDataModal/ProtectDataModal";

const Footer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenImpressumModal,
    onOpen: onOpenImpressumModal,
    onClose: onCloseImpressumModal,
  } = useDisclosure();

  return (
    <Box h={{ base: "200", md: "160px" }} display={{ base: "block", sm: "block", md: "flex" }} bg="teal.400">
      <Flex pt={12} pl={{ base: 4, md: 24 }} color="white">
        <Button onClick={() => onOpenImpressumModal()} m={4}>
          Impresszum
        </Button>
        <Button onClick={() => onOpen()} m={4}>
          Adatvédelmi tájékoztató
        </Button>
      </Flex>
      <Impressum isOpen={isOpenImpressumModal} onClose={onCloseImpressumModal} />
      <ProtectDataModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default Footer;
