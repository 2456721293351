import {
  Box,
  Text,
  Stack,
  Grid,
  Flex,
  Center,
  Image,
  Card,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  InputGroup,
  InputLeftAddon,
  Input,
  IconButton,
  useClipboard,
  Button,
  GridItem,
  Highlight,
} from "@chakra-ui/react";
import { FC, useState } from "react";
import Badges from "../utils/Badges";
import Authors from "../authors/Authors";
import News from "../News/News";
import { getDate } from "../utils/utils";
import { LinkIcon } from "@chakra-ui/icons";

type NewsProps = {
  title: string;
  description: string;
  pubdate: string;
  author: string;
  link: string;
  image?: string;
  type?: string;
  id: string;
};

type Props = {
  entries: NewsProps[];
  type?: string;
  searchText?: string;
};

const NewsTemplate: FC<Props> = ({ entries, type, searchText }) => {
  const [value, setValue] = useState("");
  const { hasCopied, onCopy } = useClipboard(value);

  return (
    <>
      <>
        {entries?.map((item) => {
          return (
            <Stack
              display={{ base: "none", md: "flex", lg: "flex" }}
              key={item.id}
              ml={6}
              mr={6}
            >
              <Center>
                <Card
                  m={2}
                  boxShadow="lg"
                  transition="transform 0.3s ease-in-out"
                  _hover={{ transform: "scale(1.05)" }}
                  w={800}
                >
                  <Flex m={2}>
                    {item.image &&
                    item.image.split("id=")[1] !==
                      "00000000-0000-0000-0000-000000000000" ? (
                      <Box>
                        <Link href={item.link} isExternal>
                          <Center>
                            <Image
                              align={"center"}
                              src={
                                item?.author?.includes("hvg")
                                  ? `https://img.hvg.hu/Img/da658e97-86c0-40f3-acd3-b0a850f32c30/${
                                      item.image.split("id=")[1]
                                    }.jpg`
                                  : item.image
                              }
                              maxH={190}
                              maxW={310}
                              key={item.id}
                            />
                            <Authors
                              author={item.author}
                              hasImage={item.image}
                            />
                          </Center>
                        </Link>
                      </Box>
                    ) : (
                      // <Image src={logo[item.author]} maxH={100} />
                      <Authors author={item.author} />
                    )}

                    <Box
                      overflow="hidden"
                      m={2}
                      mt={
                        item.image &&
                        item.image.split("id=")[1] !==
                          "00000000-0000-0000-0000-000000000000"
                          ? null
                          : "58px"
                      }
                    >
                      <Link
                        href={item.link}
                        isExternal
                        _hover={{ color: "teal.600" }}
                      >
                        <Text fontSize="md" as="b">
                          <Highlight
                            query={searchText ?? ""}
                            styles={{ px: "1", py: "1", bg: "yellow.200" }}
                          >
                            {item.title}
                          </Highlight>
                        </Text>
                      </Link>

                      <Box display="flex" alignItems="baseline">
                        <Badges type={item.type ?? type} />
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              aria-label="link-icon"
                              icon={<LinkIcon />}
                              onClick={() => setValue(item.link)}
                            />
                          </PopoverTrigger>
                          <PopoverContent w="450px" h="140px">
                            <PopoverArrow />
                            <PopoverCloseButton color="black" />
                            <PopoverHeader pt={4}>
                              A cikk linkjét meg tudod osztani
                            </PopoverHeader>
                            <PopoverBody pt={6}>
                              <Grid templateColumns="repeat(6, 1fr)" gap={2}>
                                <GridItem colSpan={4}>
                                  <InputGroup pt="6px">
                                    <InputLeftAddon>Link: </InputLeftAddon>
                                    <Input
                                      isReadOnly
                                      placeholder="link"
                                      value={item.link}
                                    />
                                  </InputGroup>
                                </GridItem>
                                <GridItem colStart={5} colEnd={7}>
                                  <Button
                                    onClick={onCopy}
                                    w="-webkit-fill-available"
                                  >
                                    <Text>
                                      {" "}
                                      {hasCopied ? "Kimásolva!" : "Kimásolom"}
                                    </Text>
                                  </Button>
                                </GridItem>
                              </Grid>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Box>

                      <Box
                        color="gray.500"
                        fontWeight="semibold"
                        letterSpacing="wide"
                        fontSize="xs"
                        textTransform="uppercase"
                        ml="2"
                      >
                        {getDate(item.pubdate)}
                      </Box>

                      <Box>
                        <Text>
                          {item.description && (
                            <Highlight
                              query={searchText ?? ""}
                              styles={{ px: "1", py: "1", bg: "yellow.200" }}
                            >
                              {item.description.length > 300
                                ? item.description
                                    .slice(0, 220)
                                    .concat("..... Klikk a címre 🔍")
                                : item.description}
                            </Highlight>
                          )}
                        </Text>
                      </Box>
                    </Box>
                  </Flex>
                </Card>
              </Center>
            </Stack>
          );
        })}
      </>

      <>
        {entries?.map((item) => {
          return (
            <Box
              justifyContent="center"
              display={{ base: "flex", md: "none", lg: "none" }}
              key={item.id}
            >
              <News
                key={item.id}
                title={item.title}
                author={item.author}
                description={item.description}
                date={item.pubdate}
                image={item.image}
                type={item.type}
                mainType={type}
                link={item.link}
                id={item.id}
                searchText={searchText}
              />
            </Box>
          );
        })}
      </>
    </>
  );
};

export default NewsTemplate;
