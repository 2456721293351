import "./App.css";
import Header from "./Header";
import { useRef } from "react";
import { useDisclosure, useMediaQuery } from "@chakra-ui/react";
import ScrollToHashElement from "./ScrollToHashElement";
import ScrollButton from "./components/ScrollButton/ScrollButton";

import MenuMobilView from "./components/menuMobilView/MenuMobilView";
import TopicNavbar from "./components/topicNavbar/TopicNavbar";
import Footer from "./Footer";
import { Helmet } from "react-helmet-async";

const App = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleToggle = () => (isOpen ? onClose() : onOpen());
  const btnRef = useRef();

  const [isLargerThan800] = useMediaQuery("(min-width: 900px)");

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Magyar Krumpli - olvasd el, friss hírek mind egy helyen."
        />
        <meta
          name="keywords"
          content="hírek, hír, belföld, külföld, bulvár, business, életmód, pénz, HVG, Telex, Portfólio, Pénzcentrum, Hír24, olimpia"
        />
        <meta property="og:title" content="Magyar Krumpli" />
        <meta
          property="og:description"
          content="Magyar Krumpli - olvasd el, friss hírek mind egy helyen."
        />
      </Helmet>
      <Header handleToggle={handleToggle} btnRef={btnRef} />
      {isLargerThan800 ? (
        <TopicNavbar />
      ) : (
        <MenuMobilView
          isOpen={isOpen}
          onClose={onClose}
          btnRef={btnRef}
          handleToggle={handleToggle}
        />
      )}

      <ScrollToHashElement />
      <ScrollButton />
      <Footer />
    </>
  );
};

export default App;
