import {
  Box,
  Text,
  Image,
  Center,
  Link,
  Card,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  InputGroup,
  InputLeftAddon,
  Input,
  Button,
  useClipboard,
  Highlight,
} from "@chakra-ui/react";
import Badges from "../utils/Badges";
import Authors from "../authors/Authors";
import { getDate } from "../utils/utils";
import { LinkIcon } from "@chakra-ui/icons";
import { useState } from "react";

type Props = {
  title: string;
  author: string;
  description: string;
  date: string;
  image?: string;
  type?: string;
  link: string;
  isLargerView?: boolean;
  id: string;
  mainType?: string;
  searchText?: string;
};

const News = ({
  title,
  author,
  description,
  date,
  image,
  type,
  link,
  id,
  mainType,
  searchText,
}: Props) => {
  const [value, setValue] = useState("");
  const { hasCopied, onCopy } = useClipboard(value);

  return (
    <Card
      as="article"
      w={700}
      minW={{ base: "0px", sm: "50px", md: "650px" }}
      ml={6}
      mr={6}
      mt={2}
      mb={2}
      bg="white"
      overflow="hidden"
      alignContent={"center"}
      justifyContent={"center"}
      key={id}
      p={4}
      transition="transform 0.3s ease-in-out"
      _hover={{ transform: "scale(1.05)" }}
    >
      {image?.split("id=")[1] !== "00000000-0000-0000-0000-000000000000" ? (
        <Box>
          <Link href={link} isExternal>
            <Center>
              <Image
                align={"center"}
                src={
                  author?.includes("hvg")
                    ? `https://img.hvg.hu/Img/da658e97-86c0-40f3-acd3-b0a850f32c30/${
                        image.split("id=")[1]
                      }.jpg`
                    : image
                }
                maxH={190}
                maxW={310}
              />
            </Center>
          </Link>
        </Box>
      ) : null}
      <Box>
        <Authors author={author} />
      </Box>

      <Box
        mt={
          image &&
          image?.split("id=")[1] !== "00000000-0000-0000-0000-000000000000"
            ? "8px"
            : "50px"
        }
        fontWeight="semibold"
        as="h4"
        lineHeight="tight"
      >
        <Link href={link} isExternal _hover={{ color: "teal.600" }}>
          <Text fontSize="md" as="b">
            <Highlight
              query={searchText ?? ""}
              styles={{ px: "1", py: "1", bg: "yellow.200" }}
            >
              {title}
            </Highlight>
          </Text>
        </Link>
      </Box>
      <Box display="flex" alignItems="baseline">
        <Badges type={type ?? mainType} />
        <Popover>
          <PopoverTrigger>
            <IconButton
              aria-label="link-icon"
              icon={<LinkIcon />}
              onClick={() => setValue(link)}
            />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton color="black" />
            <PopoverHeader pt={4}>
              A cikk linkjét meg tudod osztani
            </PopoverHeader>
            <PopoverBody pt={2}>
              <InputGroup pb="6px">
                <InputLeftAddon>Link: </InputLeftAddon>
                <Input isReadOnly placeholder="link" value={link} />
              </InputGroup>

              <Button onClick={onCopy} w="-webkit-fill-available">
                <Text> {hasCopied ? "Kimásolva!" : "Kimásolom"}</Text>
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
      <Box
        color="gray.500"
        fontWeight="semibold"
        letterSpacing="wide"
        fontSize="xs"
        textTransform="uppercase"
        ml="2"
      >
        {getDate(date)}
      </Box>
      <Box display="flex" mt="2" alignItems="center">
        <Box as="span" ml="2" color="gray.600" fontSize="sm">
          {description && (
            <Highlight
              query={searchText ?? ""}
              styles={{ px: "1", py: "1", bg: "yellow.200" }}
            >
              {description.length > 300
                ? description.slice(0, 220).concat("..... Klikk a címre 🔍")
                : description}
            </Highlight>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default News;
