import { useState, useEffect } from 'react';
import {
  SimpleGrid,
  Box,
  Button,
  useToast,
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/react';

// Define the emoji set for the puzzle pieces
const EMOJIS = ['😀', '😃', '😄', '😁', '😆', '😅', '🤣', '😂', '🙂', '🙃', '🥰', '😍'];

// Shuffle the emojis to create a random board
const shuffleEmojis = (size) => {
  const shuffled = [...EMOJIS].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, size).concat(shuffled.slice(0, size)).sort(() => 0.5 - Math.random());
};

const Memory = ({ size }) => {
  const [board, setBoard] = useState(shuffleEmojis(size * size / 2));
  const [flipped, setFlipped] = useState([]);
  const [solved, setSolved] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [moves, setMoves] = useState(0); // Move counter
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  // Flip card logic
  const flipCard = (index) => {
    if (!disabled && !solved.includes(index) && !flipped.includes(index)) {
      setFlipped([...flipped, index]);
      if (flipped.length === 0) {
        setMoves(moves => moves + 1); // Increment move counter only on the first of the pair
      }
    }
  };

  // Check if cards match
  useEffect(() => {
    if (flipped.length === 2) {
      setDisabled(true);
      if (board[flipped[0]] === board[flipped[1]]) {
        setSolved([...solved, ...flipped]);
        toast({
          title: 'Találtál egy párt!',
          description: 'Szép volt!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        resetFlips();
      } else {
        setTimeout(resetFlips, 1000);
      }
    }
  }, [flipped]);

  // Reset flips
  const resetFlips = () => {
    setFlipped([]);
    setDisabled(false);
  };

  // Reset game
  const resetGame = () => {
    setSolved([]);
    setFlipped([]);
    setMoves(0); // Reset move counter
    setBoard(shuffleEmojis(size * size / 2));
    onClose();
    toast({
      title: 'Új játék!',
      description: 'Hajrá, hajrá!',
      status: 'info',
      duration: 2000,
      isClosable: true,
    });
  };

   // Check for game completion
   useEffect(() => {
    if (solved.length === board.length) {
      onOpen() 
    }
  }, [solved, size, onOpen]);

  return (
    <VStack spacing={4}>
      <Box pt={6} pb={4}><Text as="b" color="teal" fontSize="xl">Memória játék 🤔</Text></Box>
      <Text as="b" fontSize="xl">Lépések: {moves}</Text> {/* Display move counter */}
      <SimpleGrid columns={size} spacing={2}>
        {board.map((emoji, index) => (
          <Box
            key={index}
            p={4}
            bg={solved.includes(index) ? 'green.500' : 'tomato'}
            color='white'
            rounded='md'
            shadow='md'
            textAlign='center'
            fontSize='2xl'
            cursor={disabled ? 'default' : 'pointer'}
            onClick={() => !disabled && !solved.includes(index) && !flipped.includes(index) && flipCard(index)}
          >
            {flipped.includes(index) || solved.includes(index) ? emoji : '🔳'}
          </Box>
        ))}
      </SimpleGrid>
      <Button colorScheme='teal' size="sm" onClick={resetGame}>Új játék</Button>

     
      <Modal size="xs" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
        <ModalHeader>Juhuuuu</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Megcsináltad {moves} lépésből! &#129395; Megy jobban?</ModalBody>
          <ModalFooter>
            <Button colorScheme='teal' mr={3} onClick={resetGame}>
              Új játék
            </Button>
            <Button
                colorScheme="teal"
                mr={3}
                onClick={() => {
                  onClose();
                }}
              >
                Bezár
              </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};


export default Memory;
