import { Flex, Heading, Box, Image, Text } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { format } from "date-fns";

const Header = ({ handleToggle, btnRef }) => {
  const weekday = [
    "Vasárnap",
    "hétfő",
    "Kedd",
    "Szerda",
    "Csütörtök",
    "Péntek",
    "Szombat",
  ];
  const day = new Date().getDay();
  const currDate = new Date().toISOString().slice(0, 10);
  const currentDay = weekday[day];

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      bgGradient='linear(to-r, teal.400, teal.600)'
      color="black"
    >
      <Flex
        align="center"
        mr={{ base: 5, md: 20 }}
        paddingLeft={{ base: 0, md: 20 }}
      >
        <Box>
          <Image
            src="/MagyarKrumpliLogobyDesigner-2.png"
            boxSize="100px"
            alt="test image"
          ></Image>
        </Box>
        <Heading
          as="h1"
          size="lg"
          color="white"
          fontFamily='"Days One", sans-serif'
        >
          Magyar Krumpli
        </Heading>
      </Flex>
      <Box
        color="white"
        as="b"
        display={{ base: "none", md: "block", lg: "block" }}
        pr={{ base: 0, md: 20 }}
      >
        <Text>{`${format(currDate, "yyyy.MM.dd.")}`} </Text>
        <Text>{currentDay}</Text>
      </Box>

      <Box
        pr={4}
        ref={btnRef}
        display={{ base: "block", md: "none", lg: "none" }}
        onClick={handleToggle}
        cursor="pointer"
      >
        <HamburgerIcon color="white" boxSize={8} />
      </Box>
    </Flex>
  );
};

export default Header;
