import {
  Box,
  Card,
  CardBody,
  Center,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import { FC } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./CarouselBox.style.css";
import Authors, { logo } from "../authors/Authors";
import { getDate } from "../utils/utils";

type NewsProps = {
  title: string;
  description: string;
  pubdate: string;
  author: string;
  link: string;
  image: string;
  type: string;
  id: string;
};

type List = {
  entries: NewsProps[];
};

const CarouselBox: FC<List> = (entries) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1000 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 999, min: 501 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 500, min: 100 },
      items: 0,
      slidesToSlide: 0,
    },
  };

  return (
    <div className="parent">
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
        {entries.entries.map((item, index) => {
          return (
            <div className="slider" key={index}>
              <Card
                maxW="sm"
                transition="transform 0.3s ease-in-out"
                _hover={{ transform: "scale(1.05)" }}
              >
                <CardBody>
                  <>
                    {item.image ? (
                      <Box>
                        <Link href={item.link} isExternal>
                          <Center>
                            <Image
                              align={"center"}
                              src={
                                item?.author?.includes("hvg")
                                  ? `https://img.hvg.hu/Img/da658e97-86c0-40f3-acd3-b0a850f32c30/${
                                      item.image.split("id=")[1]
                                    }.jpg`
                                  : item.image
                              }
                              key={item.id}
                            />
                            <Authors
                              author={item.author}
                              hasImage={item.image}
                            />
                          </Center>
                        </Link>
                      </Box>
                    ) : (
                      <Image
                        h="90px"
                        align={"center"}
                        src={logo[item.author]}
                      />

                      // <Authors author={item.author} hasImage={item.image} isMainPosition={true}/>
                    )}
                  </>
                  <Box overflow="hidden" m={2} mt={item.image ? null : "38px"}>
                    <Text
                      color="gray.500"
                      fontWeight="semibold"
                      letterSpacing="wide"
                      fontSize="xs"
                      textTransform="uppercase"
                      ml="2"
                    >
                      {getDate(item.pubdate)}
                    </Text>
                    <Link
                      href={item.link}
                      isExternal
                      _hover={{ color: "teal.500" }}
                    >
                      <Text fontSize="md" as="b">
                        {item.title}
                      </Text>
                    </Link>
                  </Box>
                </CardBody>
              </Card>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default CarouselBox;
