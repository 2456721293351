import { FC } from "react";
import { Box, Image } from "@chakra-ui/react";

import blikk from "../../assets/logos/blikk.png";
import hvg from "../../assets/logos/hvg.png";
import hir24 from "../../assets/logos/hir24.png";
import divany from "../../assets/logos/divany.png";
import portfolio from "../../assets/logos/portfolio.png";
import penzcentrum from "../../assets/logos/penzcentrum.png";
import telex from "../../assets/logos/telex.png";
import femina from "../../assets/logos/femina.png";
import glamour from "../../assets/logos/glamour.png";
import hir444 from "../../assets/logos/444.png";
import nemzetisport from "../../assets/logos/nemzetisport.png";
import vilaggazdasag from "../../assets/logos/vg.png";
import velvet from "../../assets/logos/velvet.png";
import index from "../../assets/logos/index.png";
import idokep from "../../assets/logos/idokep.png";
import bors from "../../assets/logos/bors.png";
import vezess from "../../assets/logos/vezess.png";
import nlc from "../../assets/logos/nlc.png";
import kiskegyed from "../../assets/logos/kiskegyed.png";
import hwsw from "../../assets/logos/hwsw.png";
import noizz from "../../assets/logos/noizz.png";

type Props = {
  author: string;
  isMainPosition?: boolean;
  hasImage?: string;
};

export const logo = {
  hvg: hvg,
  blikk: blikk,
  hir24: hir24,
  divany: divany,
  portfolio: portfolio,
  penzcentrum: penzcentrum,
  telex: telex,
  femina: femina,
  glamour: glamour,
  "444": hir444,
  nemzetisport: nemzetisport,
  velvet: velvet,
  vilaggazdasag: vilaggazdasag,
  index: index,
  bors: bors,
  idokep: idokep,
  vezess: vezess,
  nlc: nlc,
  kiskegyed: kiskegyed,
  hwsw: hwsw,
  noizz: noizz
};

const Authors: FC<Props> = ({ author, isMainPosition }) => {
  return (
    <Box
      p={1}
      pos="absolute"
      left={isMainPosition ? "0px" : "8px"}
      top={isMainPosition ? "0px" : "8px"}
    >
      <Image h="50px" src={logo[author]} />
    </Box>
  );
};

export default Authors;
