import { Box, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import reqmate from "reqmate";
import NewsPost, { NewsProps } from "../newsPost/NewsPost";

const baseURL = "https://api-dev.magyarkrumpli.hu";

const Search = ({ setSearch }) => {

  const [entries, setEntries] = useState<NewsProps[] | undefined>();
  const [searchText, setSearchText] = useState('')
  const size = `?&size=25`;

  const handlerOnChange = async (value) => {
    if (value.length >= 3) {
      setSearch(true)
      try {
        const response = await reqmate
          .get(`${baseURL}/search${size}&search=${value}`)
          .setCaching(500000)
          .send();
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res: any = response;
        setEntries(res.data.results);
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setEntries([])
      setSearch(false)
    }
  };

  return (
    <Box p={4} ml={8} mr={8}>
      <Text as="b" color="teal">Keresés:</Text>
      <Input
        bgColor="white"
        placeholder="Keress cikket"
        onChange={(e) => {
          handlerOnChange(e.target.value);
          setSearchText(e.target.value)
        }}
      />
      {entries && entries.length > 0 ? (
        // <ul style={{ backgroundColor: 'white', padding: 'absolute'}}>
        //   {entries.map((item) => {
        //     return <li style={{ backgroundColor: 'white' }} key={item.id}>{item.title}</li>;
        //   })}
        // </ul>
        <NewsPost entries={entries} searchText={searchText}/>
      ) : null}
    </Box>
  );
};

export default Search;
