import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
} from "@chakra-ui/react";

const Impressum = ({ onClose, isOpen }) => {
  return (
    <Modal onClose={onClose} size="full" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mt={12} ml={{ base: 4, sm: 4, md: 12, lg: 12 }}>Impresszum</ModalHeader>
        <ModalCloseButton  color="black"/>
        <ModalBody>
          <Box p={{ base: 4, sm: 4, md: 12, lg: 12 }}>
            <p>Kiadó: Miller Productions Kft.</p>
            <p>Email: info@magyarkrumpli.hu</p>
            <p>
              Székhely: 5/1. 1152 Budapest, Illyés Gyula utca 2-4. A. ép. Fsz.
              7. ajtó
            </p>
            <p>Cégjegyzékszám: 01-09-433079</p>
            <p>Adószám: 32605288-2-42</p>
          </Box>
          <Box p={{ base: 4, sm: 4, md: 12, lg: 12 }}>
            <p>
              {" "}
              A Miller Production Kft. által üzemeltetett weboldalon található
              tartalmak, linkek és képek más médiumoktól származnak. Ezekért a
              tartalmakért, azok pontosságáért, teljességéért vagy
              időszerűségéért felelősséget nem vállalunk.{" "}
            </p>
            <p>
              Az oldalon megjelenő információk csak tájékoztató jellegűek, és
              nem minősülnek hivatalos forrásnak. Az oldalon található
              tartalmak, beleértve, de nem kizárólagosan a szövegeket, képeket,
              grafikai elemeket és egyéb anyagokat, szerzői jogvédelem alatt
              állnak. Ezek másolása, terjesztése, kereskedelmi célú
              felhasználása, vagy bármilyen egyéb módon történő hasznosítása
              kizárólag a Miller Productions Kft. előzetes írásbeli engedélyével
              lehetséges.{" "}
            </p>
            <p>
              A weboldalon található linkek harmadik fél weboldalaira mutatnak.
              Az ilyen linkek követése kizárólag a felhasználó saját
              felelősségére történik, és azon külső oldalak tartalmáért
              semmilyen felelősséget nem vállalunk.
            </p>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Bezár</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Impressum;
