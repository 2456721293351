import { FC } from "react";
import CarouselBox from "../carouselBox/CarouselBox";
import { Box, Grid, GridItem, Skeleton } from "@chakra-ui/react";

type NewsProps = {
  title: string;
  description: string;
  pubdate: string;
  author: string;
  link: string;
  image: string;
  type: string;
  id: string;
};

type Props = {
  topic?: string;
  portal?: string;
  entries?: NewsProps[];
};

const NewsBox: FC<Props> = ({ entries }) => {

  if (!entries)
    return (
      <Box boxShadow="lg" bg="white" height="220px" p="18px"  display={{ base: "none", sm: "none", md: "block", lg: "block" }}>
        <Grid templateColumns="repeat(4, 1fr)" gap={6}>
          <GridItem w="100%" h={40}>
            <Skeleton h="100%" />
          </GridItem>
          <GridItem w="100%" h={40}>
            <Skeleton h="100%" />
          </GridItem>
          <GridItem w="100%" h={40}>
            <Skeleton h="100%" />
          </GridItem>
          <GridItem w="100%" h={40}>
            <Skeleton h="100%" />
          </GridItem>
        </Grid>
      </Box>
    );

  return (
    <Box pl={4} pr={4}>
      <CarouselBox entries={entries} />

      {/* <GridNewsBox entries={entries} /> */}
    </Box>
  );
};

export default NewsBox;
