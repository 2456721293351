import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const ProtectDataModal = ({ onClose, isOpen }) => {
  return (
    <Modal onClose={onClose} size="full" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton color="black" />
        <ModalBody>
          <Box
            p={{ base: 4, sm: 4, md: 12, lg: 12 }}
            ml={{ base: 4, sm: 4, md: 20, lg: 20 }}
            mr={{ base: 4, sm: 4, md: 20, lg: 20 }}
          >
            <Text fontSize="3xl">Adatvédelmi Tájékoztató</Text>
            <Box mb={6}>
              <Text fontSize="2xl">Bevezetés</Text>
              <Text>
                Az Ön adatainak védelme és biztonsága kiemelten fontos
                számunkra. Ez az adatvédelmi tájékoztató részletezi, hogyan
                használjuk fel az Ön adatait a weboldalunkon keresztül.
              </Text>
            </Box>
            <Box mb={6}>
              <Text fontSize="2xl">Gyűjtött adatok </Text>
              <Text>
                Weboldalunk kizárólag a Google Analytics szolgáltatást
                használja, hogy elemezzük a látogatói tevékenységeket és
                javítsuk a weboldalunk működését. A Google Analytics
                használatával gyűjtött adatok tartalmazhatják a következőket:
              </Text>
              <UnorderedList>
                <ListItem>Böngésző típusa és beállításai</ListItem>
                <ListItem>Az Ön eszközének típusa és beállításai</ListItem>
                <ListItem>Operációs rendszer</ListItem>
                <ListItem>
                  Webhelyeink eléréséhez használt hivatkozó URL
                </ListItem>
                <ListItem>Az Ön IP-címe (anonimizálva)</ListItem>
              </UnorderedList>
            </Box>
            <Box mb={6}>
              <Text fontSize="2xl">Az adatok felhasználása</Text>
              <Text>A gyűjtött adatokat a következő célokra használjuk:</Text>
              <UnorderedList>
                <ListItem>
                  Weboldalunk teljesítményének és funkcionalitásának javítása
                </ListItem>
                <ListItem>Látogatói trendek és tevékenységek elemzése</ListItem>
                <ListItem>Anonimizált statisztikák készítése</ListItem>
              </UnorderedList>
            </Box>
            <Box mb={6}>
              <Text fontSize="2xl">Adatmegőrzés</Text>
              <Text>
                A Google Analytics által gyűjtött adatokat a Google Analytics
                adatvédelmi irányelveinek megfelelően őrizzük meg.
              </Text>
            </Box>
            <Box mb={6}>
              <Text fontSize="2xl">Felhasználói jogok</Text>
              <Text>
                Mivel az adatokat anonimizált formában gyűjtjük, nem áll
                módunkban az Ön személyes adatait közvetlenül azonosítani vagy
                azokkal kapcsolatban jogokat biztosítani. Ha azonban bármilyen
                kérdése van az adatvédelmi gyakorlatunkkal kapcsolatban, kérjük,
                lépjen kapcsolatba velünk az alábbi elérhetőségek egyikén.
              </Text>
            </Box>
            <Box mb={6}>
              <Text fontSize="2xl">Biztonság</Text>
              <Text>
                Megfelelő technikai és szervezeti intézkedéseket teszünk annak
                érdekében, hogy az Ön adatait véletlen vagy jogellenes
                megsemmisítés, elvesztés, módosítás, jogosulatlan közlés vagy
                hozzáférés ellen védjük.
              </Text>
            </Box>
            <Box mb={6}>
              <Text fontSize="2xl">Cookie-k használata</Text>
              <Text>
                A Google Analytics cookie-kat használ a látogatói tevékenységek
                nyomon követésére. Ezek a cookie-k anonimizált adatokat
                gyűjtenek, és nem teszik lehetővé az Ön személyes azonosítását.
                A cookie-k használatáról és kezeléséről bővebb információt az
                alábbi linkre kattintva találhat:{" "}
                <Link
                  target="_blank"
                  to="https://policies.google.com/privacy?hl=hu"
                >
                  Google Analytics cookie-k és adatvédelmi irányelvek
                </Link>
              </Text>
            </Box>
            <Box mb={6}>
              <Text fontSize="2xl">Kapcsolat</Text>
              <Text>
                Ha bármilyen kérdése vagy észrevétele van az adatvédelmi
                tájékoztatóval kapcsolatban, kérjük, lépjen kapcsolatba velünk
                az alábbi elérhetőségen:
              </Text>
              <UnorderedList>
                <ListItem>Email: info@magyarkrumpli.hu</ListItem>
              </UnorderedList>
            </Box>
            <Box mb={6}>
              <Text fontSize="2xl">
                Változások az adatvédelmi tájékoztatóban
              </Text>
              <Text>
                Fenntartjuk a jogot, hogy időről időre frissítsük ezt az
                adatvédelmi tájékoztatót. Az esetleges változásokról ezen a
                weboldalon keresztül értesítjük látogatóinkat. Az adatvédelmi
                tájékoztató legutóbbi frissítésének dátuma: [2024. július 24.]
              </Text>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Bezár</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProtectDataModal;
