// CookieBanner.js
import { useState } from "react";
import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import ProtectDataModal from "../protectDataModal/ProtectDataModal";

const CookieBanner = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAccepted, setIsAccepted] = useState(
    localStorage.getItem("cookieConsent") === "accepted"
  );
  const [isDeclined, setIsDeclined] = useState(
    localStorage.getItem("cookieConsent") === "declined"
  );

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setIsAccepted(true);
    setIsDeclined(false);
    // Itt indíthatjuk a Google Analytics betöltését
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined");
    setIsAccepted(false);
    setIsDeclined(true);
    // Itt blokkolhatjuk a Google Analytics betöltését
  };

  if (isAccepted || isDeclined) {
    return null;
  }

  return (
    <>
      <Box
        position="fixed"
        bottom="0"
        width="100%"
        bg="gray.800"
        color="white"
        p={4}
        zIndex={1000}
      >
        <Flex justify="space-between" align="center" display={{ base: 'block', sm: 'block', md: 'flex', lg: 'flex' }}>
          <Text>
            A weboldalunk cookie-kat használ a jobb felhasználói élmény
            érdekében. A weboldal használatával elfogadja a cookie-k
            használatát.{" "}
            <a
              onClick={() => onOpen()}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              További információ
            </a>
            .
          </Text>
          <Button colorScheme="blue" onClick={handleAccept}>
            Elfogadom
          </Button>
          <Button colorScheme="gray" onClick={handleDecline}>
            Elutasítom
          </Button>
        </Flex>
      </Box>
      <ProtectDataModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default CookieBanner;
