import { useState, useEffect, useCallback } from "react";
import { Box, Button, Flex, Text, useBoolean } from "@chakra-ui/react";

const gridSize = 20;
// Adjust this for a faster or slower game
const gameSpeed = 100;
const startSpeed = 300;

const getInitialSnake = () => [
  { x: Math.floor(gridSize / 2), y: Math.floor(gridSize / 2) },
];

const getInitialFood = (snake) => {
  let newFood;
  do {
    newFood = {
      x: Math.floor(Math.random() * gridSize),
      y: Math.floor(Math.random() * gridSize),
    };
  } while (
    snake.some((segment) => segment.x === newFood.x && segment.y === newFood.y)
  );
  return newFood;
};

const SnakeGame = () => {
  const [snake, setSnake] = useState(getInitialSnake());
  const [food, setFood] = useState(getInitialFood(snake));
  const [direction, setDirection] = useState({ x: 0, y: -1 });
  const [isRunning, setIsRunning] = useBoolean(false);
  const [touchStart, setTouchStart] = useState({ x: null, y: null });

  const handleKeyPress = useCallback(
    (event) => {
      switch (event.key) {
        case "ArrowUp":
          if (direction.y === 0) setDirection({ x: 0, y: -1 });
          break;
        case "ArrowDown":
          if (direction.y === 0) setDirection({ x: 0, y: 1 });
          break;
        case "ArrowLeft":
          if (direction.x === 0) setDirection({ x: -1, y: 0 });
          break;
        case "ArrowRight":
          if (direction.x === 0) setDirection({ x: 1, y: 0 });
          break;
        default:
          break;
      }
    },
    [direction]
  );

  const moveSnake = useCallback(() => {
    setSnake((prevSnake) => {
      const newSnake = [...prevSnake];
      const newHead = {
        x: prevSnake[0].x + direction.x,
        y: prevSnake[0].y + direction.y,
      };

      // Check for collisions with walls
      if (
        newHead.x >= gridSize ||
        newHead.x < 0 ||
        newHead.y >= gridSize ||
        newHead.y < 0
      ) {
        setIsRunning.off();
        return prevSnake;
      }

      // Check for collisions with self
      if (
        newSnake.some(
          (segment) => segment.x === newHead.x && segment.y === newHead.y
        )
      ) {
        setIsRunning.off();
        return prevSnake;
      }

      // Check if the snake has eaten the food
      if (newHead.x === food.x && newHead.y === food.y) {
        setFood(getInitialFood(snake));
      } else {
        newSnake.pop();
      }

      newSnake.unshift(newHead);
      return newSnake;
    });
  }, [direction, food, setIsRunning]);

  useEffect(() => {
    if (isRunning) {
      const interval = setInterval(
        moveSnake,
        snake.length <= 12 ? startSpeed : gameSpeed
      );
      return () => clearInterval(interval);
    }
  }, [isRunning, moveSnake]);

  document.addEventListener("keydown", function (event) {
    if (
      event.key === "ArrowUp" ||
      event.key === "ArrowDown" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight"
    ) {
      // Prevent the default action (scrolling) from occurring
      event.preventDefault();
    }
  });

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress]);

  const startGame = () => {
    setSnake(getInitialSnake());
    setFood(getInitialFood(snake));
    setDirection({ x: 0, y: -1 });
    setIsRunning.on();
  };

  // Touch controls for mobile devices
  const handleTouchStart = (e) => {
    const firstTouch = e.touches[0];
    setTouchStart({
      x: firstTouch.clientX,
      y: firstTouch.clientY,
    });
  };

  const handleTouchMove = (e) => {
    if (!touchStart.x || !touchStart.y) {
      return;
    }

    const xUp = e.touches[0].clientX;
    const yUp = e.touches[0].clientY;

    const xDiff = touchStart.x - xUp;
    const yDiff = touchStart.y - yUp;

    // Determine swipe direction and update the direction state accordingly
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      // Horizontal swipe
      if (xDiff > 0 && direction.x === 0) {
        // Left swipe
        setDirection({ x: -1, y: 0 });
      } else if (xDiff < 0 && direction.x === 0) {
        // Right swipe
        setDirection({ x: 1, y: 0 });
      }
    } else {
      // Vertical swipe
      if (yDiff > 0 && direction.y === 0) {
        // Up swipe
        setDirection({ x: 0, y: -1 });
      } else if (yDiff < 0 && direction.y === 0) {
        // Down swipe
        setDirection({ x: 0, y: 1 });
      }
    }

    // Reset touchStart state after determining swipe direction
    setTouchStart({ x: null, y: null });
  };

  const handleButtonClick = (direction) => {
    setDirection(direction);
  };

  return (
    <Flex direction="column" align="center" justify="center">
      <Box pt={6} pb={4}>
        <Text as="b" color="teal" fontSize="xl">
          Snake 🐍
        </Text>
      </Box>
      <Box>
        <Text as="b">Almák száma: {snake.length - 1}</Text>
      </Box>
      <Box
        id="game-board"
        position="relative"
        h={{base:"350px", sm: "400px", md: "400px", lg: "400px"}}
        w={{base:"350px", sm: "400px", md: "400px", lg: "400px"}}
        bg="black"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        {snake.map((segment, index) => (
          <Box
            key={index}
            position="absolute"
            bg="green.500"
            w={{base:`${350 / gridSize}px`, sm: `${400 / gridSize}px`, md: `${400 / gridSize}px`, lg: `${400 / gridSize}px`}}
            h={{base:`${350 / gridSize}px`, sm: `${400 / gridSize}px`, md: `${400 / gridSize}px`, lg: `${400 / gridSize}px`}}
            left={{base:`${segment.x * (350 / gridSize)}px`, sm: `${segment.x * (400 / gridSize)}px`, md: `${segment.x * (400 / gridSize)}px`, lg: `${segment.x * (400 / gridSize)}px`}}
            top={{base:`${segment.y * (350 / gridSize)}px`, sm: `${segment.y * (400 / gridSize)}px`, md: `${segment.y * (400 / gridSize)}px`, lg: `${segment.y * (400 / gridSize)}px`}}
          />
        ))}
        <Box
          position="absolute"
          bg="black"
          w={{base:`${350 / gridSize}px`, sm: `${400 / gridSize}px`, md: `${400 / gridSize}px`, lg: `${400 / gridSize}px`}}
          h={{base:`${350 / gridSize}px`, sm: `${400 / gridSize}px`, md: `${400 / gridSize}px`, lg: `${400 / gridSize}px`}}
          left={{base:`${food.x * (350 / gridSize)}px`, sm: `${food.x * (400 / gridSize)}px`, md: `${food.x * (400 / gridSize)}px`, lg: `${food.x * (400 / gridSize)}px`}}
          top={{base:`${food.y * (350 / gridSize)}px`, sm: `${food.y * (400 / gridSize)}px`, md: `${food.y * (400 / gridSize)}px`, lg: `${food.y * (400 / gridSize)}px`}}
        >
          🍎
        </Box>
        
      </Box>
      <Flex display={{ base: "block", sm: "block", md: "none", lg: "none" }}>
      <Button fontSize="xx-large" onClick={() => handleButtonClick({ x: -1, y: 0 })}>⬅️</Button>
      <Button fontSize="xx-large" onClick={() => handleButtonClick({ x: 0, y: -1 })}>⬆️</Button>
      <Button fontSize="xx-large" onClick={() => handleButtonClick({ x: 0, y: 1 })}>⬇️</Button>
      <Button fontSize="xx-large" onClick={() => handleButtonClick({ x: 1, y: 0 })}>➡️</Button>
    </Flex>
      <Button
        colorScheme="teal"
        size="sm"
        mt={4}
        onClick={startGame}
        isDisabled={isRunning}
      >
        Új játék
      </Button>
      <Box display={{ base: "none", sm: "none", md: "block", lg: "block" }}><Text>A billentyű nyilak segítségével irányíthatod ⬅️⬆️⬇️➡️</Text></Box>
    </Flex>
  );
};

export default SnakeGame;
