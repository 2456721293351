import { Badge } from "@chakra-ui/react";

const Badges = ({ type }) => {
  if (type === "gazdasag") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="teal">
        Gazdaság
      </Badge>
    );
  } else if (type === "tech") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="red">
        Tech
      </Badge>
    );
  } else if (type === "belfold") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="cyan">
        Belföld
      </Badge>
    );
  } else if (type === "kulfold") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="purple">
        Külföld
      </Badge>
    );
  } else if (type === "cegauto" || type === "auto") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="blue">
        Autó
      </Badge>
    );
  } else if (type === "eletmod") {
    return (
      <Badge borderRadius="full" px="2" variant="outline" colorScheme="green">
        Életmód
      </Badge>
    );
  } else if (type === "sport") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="green">
        Sport
      </Badge>
    );
  } else if (type === "utazas") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="cyan" variant="outline">
        Utazás
      </Badge>
    );
  } else if (type === "kultura") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="orange">
        Kultúra
      </Badge>
    );
  } else if (type === "szorakozas") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="pink" variant="outline">
        Szórakozás
      </Badge>
    );
  } else if (type === "idojaras") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="purple" variant="outline">
        Időjárás
      </Badge>
    );
  } else if (type === "otthon") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="orange" variant="outline">
        Otthon
      </Badge>
    );
  } else if (type === "gasztro") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="teal" variant="outline">
        Gasztro
      </Badge>
    );
  } else if (type === "bulvar") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="red" variant="outline">
        Bulvár
      </Badge>
    );
  } else if (type === "aktualis") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="yellow" variant="outline">
        Aktuális
      </Badge>
    );
  } else if (type === "friss") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="yellow">
        Friss
      </Badge>
    );
  } else if (type === "business") {
    return (
      <Badge borderRadius="full" px="2" colorScheme="gray">
        Vállalkozás
      </Badge>
    );
  } else {
    return (
      <Badge borderRadius="full" px="2" variant="outline">
        Egyéb
      </Badge>
    );
  }
};

export default Badges;
