import { useEffect, useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import { Button } from "./ScrollButton.style";
import { Tooltip } from "@chakra-ui/react";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);

  }, []);

  return (
    <Tooltip
      label="Ugrás a lap tetejére"
      bg="gray.600"
      color="white"
      placement="bottom-end"
      display={visible ? "true" : "none"}
    >
      <Button style={{ display: visible ? "inline" : "none" }}>
        <FaArrowCircleUp
          onClick={scrollToTop}
          // style={{ display: visible ? "inline" : "none" }}
        />
      </Button>
    </Tooltip>
  );
};

export default ScrollButton;
